/* BASES AND DEFAULTS ---------------------------------------------------------------------------- */
.button {
    height: 36px;
    line-height: initial;
    font-size: 1rem;
    letter-spacing: 1px;
    padding: 7px 15px;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid var(--prism-color-grey-300);
    border-radius: 2px;
    background-color: white;
    color: var(--prism-color-grey-800);
    transition: all 0.2s;
    position: relative;
}

.button:hover {
    background-color: var(--prism-color-grey-100);
}

.button.active.focus,
.button.active:focus,
.button.focus,
.button:active.focus,
.button:active:focus,
.button:focus,
.button.focus,
.button:focus {
    outline: 0;
}

.fullWidth {
    width: 100%;
}

.content {
    display: flex;
    column-gap: 12px;
    justify-content: center;
    align-items: center;
}

.contentHidden {
    composes: content;
    opacity: 0;
}

.icon {
    height: 14px;
    width: 14px;
}

.button:disabled {
    cursor: not-allowed;
    background-color: #dbdee9;
}

.button:disabled:hover {
    background-color: #dbdee9;
}

.variant_outline {
    background-color: transparent;
}

.variant_outline:hover {
    background-color: var(--prism-color-grey-200);
}

/* SIZES ---------------------------------------------------------------------------------------- */

.size_tiny {
    height: 24px;
    padding: 0px 12px;
    font-size: 13px;
}

.size_small {
    height: 30px;
    padding: 4px 13px;
}

.size_big {
    height: 42px;
    padding: 4px 17px;
}

/* COLORS --------------------------------------------------------------------------------------- */

/* Primary */
.color_primary {
    background-color: var(--prism-color-primary);
    border-color: #e7505e;
    color: #fff;
}

.color_primary:hover {
    background-color: #ee5160;
}

.color_primary:disabled {
    background-color: #c4737b;
    border-color: #c4737b;
}

.color_primary:disabled:hover {
    background-color: #c4737b;
}

.variant_outline.color_primary {
    background-color: transparent;
    color: var(--prism-color-primary);
}

.variant_outline.color_primary:hover {
    background-color: var(--prism-color-primary);
    color: white;
}

/* Secondary */
.color_secondary {
    background-color: var(--prism-color-secondary);
    border-color: #2272c9;
    color: #fff;
}

.color_secondary:hover {
    background-color: #1e6dc3;
}

.color_secondary:disabled {
    background-color: #4985c5;
}

.color_secondary:disabled:hover {
    background-color: #4985c5;
}

.variant_outline.color_secondary {
    background-color: transparent;
    color: var(--prism-color-secondary);
}

.variant_outline.color_secondary:hover {
    background-color: var(--prism-color-secondary);
    color: white;
}

/* Tertiary Blue */
.color_tertiary-blue {
    background-color: var(--prism-color-tertiary-blue);
    border-color: #8dade2;
    color: #223d69;
}

.color_tertiary-blue:hover {
    background-color: #8dade2;
}

.color_tertiary-blue:disabled {
    background-color: #9ab7e7;
}

.color_tertiary-blue:disabled:hover {
    background-color: #9ab7e7;
}

.variant_outline.color_tertiary-blue {
    background-color: transparent;
}

.variant_outline.color_tertiary-blue:hover {
    background-color: var(--prism-color-tertiary-blue);
}

/* Tertiary Pink */
.color_tertiary-pink {
    background-color: var(--prism-color-tertiary-pink);
    border-color: #efa7a7;
    color: #260808;
}

.color_tertiary-pink:hover {
    background-color: #efa7a7;
}

.color_tertiary-pink:disabled {
    background-color: #dcbfbf;
}

.color_tertiary-pink:disabled:hover {
    background-color: #dcbfbf;
}

.variant_outline.color_tertiary-pink {
    background-color: transparent;
}

.variant_outline.color_tertiary-pink:hover {
    background-color: var(--prism-color-tertiary-pink);
}

/* Dark Green */
.color_dark-green {
    background-color: var(--green700);
    border-color: var(--green800);
    color: var(--green050);
}

.color_dark-green:hover {
    background-color: var(--green600);
}

.color_dark-green:disabled {
    /* FIXME */
}

.color_dark-green:disabled:hover {
    /* FIXME */
}

.variant_outlinecolor_dark-green {
    /* FIXME */
}

.variant_outline.color_dark-green:hover {
    /* FIXME */
}

/* VARIANTS ------------------------------------------------------------------------------------- */

.variant_floating {
    composes: button;
    transition: all 0.2s ease-out;
    box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 14%);
}

.variant_floating:hover {
    box-shadow: 0px 1px 2px 1px rgb(0 0 0 / 14%);
    transform: translateY(-1px);
}

.variant_floating:active {
    transform: initial;
}

/* LOADER ----------------------------------------------------------------------------------------*/
@keyframes rotate {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(180deg);
    }
}
.loaderContainer {
    height: 100%;
    width: 100%;
    left: 0;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    animation-name: rotate;
    animation-duration: 3s;
    animation-timing-function: cubic-bezier(1, 1, 1, 1);
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    height: 18px;
    transition: opacity 0.5s ease-in-out, scale 0.5s ease-in-out;
}
