html {
    word-break: normal;
}

p {
    margin: 0 0 10px;
}

label {
    width: 100%;
    font-weight: 400;
    color: var(--prism-color-grey-900);
    margin-bottom: 5px;
    margin-top: 16px;
    display: inline-block;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

/* // OLD BOOTSTRAP STUFF */
.hidden {
    display: none !important;
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}
@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}
