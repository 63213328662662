.text-blue {
    color: #4a6cff;
}
.text-green {
    color: #05ce9f;
}
.text-red {
    color: #ce2c20;
}
.text-yellow {
    color: #fec163;
}
.text-white {
    color: #ffffff;
}

.text-pink {
    color: #ff2954;
}
.text-pink-100 {
    color: #ffe3e9;
}
.text-pink-200 {
    color: #ffc9d5;
}
.text-pink-300 {
    color: #ffa6b9;
}
.text-pink-400 {
    color: #ff7591;
}
.text-pink-500 {
    color: #ff2954;
}
.text-pink-600 {
    color: #e5254c;
}
.text-pink-700 {
    color: #cc1439;
}
.text-pink-800 {
    color: #99001f;
}
.text-pink-900 {
    color: #80001a;
}

.text-gray-100 {
    color: var(--prism-color-grey-100);
}
.text-gray-200 {
    color: var(--prism-color-grey-200);
}
.text-gray-300 {
    color: var(--prism-color-grey-300);
}
.text-gray-400 {
    color: var(--prism-color-grey-400);
}
.text-gray-500 {
    color: var(--prism-color-grey-500);
}
.text-gray-600 {
    color: var(--prism-color-grey-600);
}
.text-gray-700 {
    color: var(--prism-color-grey-700);
}
.text-gray-800 {
    color: var(--prism-color-grey-800);
}
.text-gray-900 {
    color: var(--prism-color-grey-900);
}

.text-purple {
    color: #00188e;
}
.text-purple-100 {
    color: #e5eaff;
}
.text-purple-200 {
    color: #abb8f5;
}
.text-purple-300 {
    color: #7386e5;
}
.text-purple-400 {
    color: #2741c2;
}
.text-purple-500 {
    color: #00188e;
}
.text-purple-600 {
    color: #00188e;
}
.text-purple-700 {
    color: #00105c;
}
.text-purple-800 {
    color: #00105c;
}
.text-purple-900 {
    color: #000b42;
}

.bg-blue {
    background-color: #4a6cff;
}
.bg-green {
    background-color: #05ce9f;
}
.bg-red {
    background-color: #ce2c20;
}
.bg-yellow {
    background-color: #fec163;
}
.bg-white {
    background-color: #ffffff;
}
.bg-orange {
    background-color: #ed7443;
}

.bg-pink {
    background-color: #ff2954;
}
.bg-pink-100 {
    background-color: #ffe3e9;
}
.bg-pink-200 {
    background-color: #ffc9d5;
}
.bg-pink-300 {
    background-color: #ffa6b9;
}
.bg-pink-400 {
    background-color: #ff7591;
}
.bg-pink-500 {
    background-color: #ff2954;
}
.bg-pink-600 {
    background-color: #e5254c;
}
.bg-pink-700 {
    background-color: #cc1439;
}
.bg-pink-800 {
    background-color: #99001f;
}
.bg-pink-900 {
    background-color: #80001a;
}

.bg-gray-100 {
    background-color: var(--prism-color-grey-100);
}
.bg-gray-200 {
    background-color: var(--prism-color-grey-200);
}
.bg-gray-300 {
    background-color: var(--prism-color-grey-300);
}
.bg-gray-400 {
    background-color: var(--prism-color-grey-400);
}
.bg-gray-500 {
    background-color: var(--prism-color-grey-500);
}
.bg-gray-600 {
    background-color: var(--prism-color-grey-600);
}
.bg-gray-700 {
    background-color: var(--prism-color-grey-700);
}
.bg-gray-800 {
    background-color: var(--prism-color-grey-800);
}
.bg-gray-900 {
    background-color: var(--prism-color-grey-900);
}

.bg-purple {
    background-color: #00188e;
}
.bg-purple-100 {
    background-color: #e5eaff;
}
.bg-purple-200 {
    background-color: #abb8f5;
}
.bg-purple-300 {
    background-color: #7386e5;
}
.bg-purple-400 {
    background-color: #2741c2;
}
.bg-purple-500 {
    background-color: #00188e;
}
.bg-purple-600 {
    background-color: #00188e;
}
.bg-purple-700 {
    background-color: #00105c;
}
.bg-purple-800 {
    background-color: #00105c;
}
.bg-purple-900 {
    background-color: #000b42;
}

.border-blue {
    border-color: #4a6cff;
}
.border-green {
    border-color: #05ce9f;
}
.border-red {
    border-color: #ce2c20;
}
.border-yellow {
    border-color: #fec163;
}
.border-white {
    border-color: #ffffff;
}

.border-pink {
    border-color: #ff2954;
}
.border-pink-100 {
    border-color: #ffe3e9;
}
.border-pink-200 {
    border-color: #ffc9d5;
}
.border-pink-300 {
    border-color: #ffa6b9;
}
.border-pink-400 {
    border-color: #ff7591;
}
.border-pink-500 {
    border-color: #ff2954;
}
.border-pink-600 {
    border-color: #e5254c;
}
.border-pink-700 {
    border-color: #cc1439;
}
.border-pink-800 {
    border-color: #99001f;
}
.border-pink-900 {
    border-color: #80001a;
}
.border-gray-100 {
    border-color: var(--prism-color-grey-100);
}
.border-gray-200 {
    border-color: var(--prism-color-grey-200);
}
.border-gray-300 {
    border-color: var(--prism-color-grey-300);
}
.border-gray-400 {
    border-color: var(--prism-color-grey-400);
}
.border-gray-500 {
    border-color: var(--prism-color-grey-500);
}
.border-gray-600 {
    border-color: var(--prism-color-grey-600);
}
.border-gray-700 {
    border-color: var(--prism-color-grey-700);
}
.border-gray-800 {
    border-color: var(--prism-color-grey-800);
}
.border-gray-900 {
    border-color: var(--prism-color-grey-900);
}

.border-purple {
    border-color: #00188e;
}
.border-purple-100 {
    border-color: #e5eaff;
}
.border-purple-200 {
    border-color: #abb8f5;
}
.border-purple-300 {
    border-color: #7386e5;
}
.border-purple-400 {
    border-color: #2741c2;
}
.border-purple-500 {
    border-color: #00188e;
}
.border-purple-600 {
    border-color: #00188e;
}
.border-purple-700 {
    border-color: #00105c;
}
.border-purple-800 {
    border-color: #00105c;
}
.border-purple-900 {
    border-color: #000b42;
}

.border-0 {
    border-width: 0;
    border-style: solid;
}
.border {
    border-width: 1px;
    border-style: solid;
}
.border-1 {
    border-width: 1px;
    border-style: solid;
}
.border-2 {
    border-width: 2px;
    border-style: solid;
}
.border-4 {
    border-width: 4px;
    border-style: solid;
}
.border-8 {
    border-width: 8px;
    border-style: solid;
}

.border-bottom-0 {
    border-bottom-width: 0;
    border-bottom-style: solid;
}
.border-bottom {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}
.border-bottom-1 {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}
.border-bottom-2 {
    border-bottom-width: 2px;
    border-bottom-style: solid;
}
.border-bottom-4 {
    border-bottom-width: 4px;
    border-bottom-style: solid;
}
.border-bottom-8 {
    border-bottom-width: 8px;
    border-bottom-style: solid;
}

.border-left-0 {
    border-left-width: 0;
    border-left-style: solid;
}
.border-left {
    border-left-width: 1px;
    border-left-style: solid;
}
.border-left-1 {
    border-left-width: 1px;
    border-left-style: solid;
}
.border-left-2 {
    border-left-width: 2px;
    border-left-style: solid;
}
.border-left-4 {
    border-left-width: 4px;
    border-left-style: solid;
}
.border-left-8 {
    border-left-width: 8px;
    border-left-style: solid;
}

.border-right-0 {
    border-right-width: 0;
    border-right-style: solid;
}
.border-right {
    border-right-width: 1px;
    border-right-style: solid;
}
.border-right-1 {
    border-right-width: 1px;
    border-right-style: solid;
}
.border-right-2 {
    border-right-width: 2px;
    border-right-style: solid;
}
.border-right-4 {
    border-right-width: 4px;
    border-right-style: solid;
}
.border-right-8 {
    border-right-width: 8px;
    border-right-style: solid;
}

.border-top-0 {
    border-top-width: 0;
    border-top-style: solid;
}
.border-top {
    border-top-width: 1px;
    border-top-style: solid;
}
.border-top-1 {
    border-top-width: 1px;
    border-top-style: solid;
}
.border-top-2 {
    border-top-width: 2px;
    border-top-style: solid;
}
.border-top-4 {
    border-top-width: 4px;
    border-top-style: solid;
}
.border-top-8 {
    border-top-width: 8px;
    border-top-style: solid;
}

.rounded-none {
    border-radius: 0;
}
.rounded-sm {
    border-radius: 0.125rem;
}
.rounded {
    border-radius: 0.25rem;
}
.rounded-lg {
    border-radius: 0.5rem;
}
.rounded-full {
    border-radius: 9999px;
}

.thin-shadow {
    box-shadow: 3px 3px 7px 0px rgba(28, 23, 150, 0.07);
}
.heavy-shadow {
    box-shadow: 5px 5px 24px rgba(28, 23, 150, 0.17);
}
