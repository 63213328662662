.text-xs {
    font-size: 0.75rem;
}
.text-sm {
    font-size: 0.875rem;
}
.text-base {
    font-size: 1rem;
}
.text-lg {
    font-size: 1.125rem;
}
.text-xl {
    font-size: 1.25rem;
}
.text-2xl {
    font-size: 1.5rem;
}
.text-3xl {
    font-size: 1.875rem;
}
.text-4xl {
    font-size: 2.25rem;
}
.text-5xl {
    font-size: 3rem;
}
.text-6xl {
    font-size: 4rem;
}

.text-thin {
    font-weight: 200;
}
.text-normal {
    font-weight: 400;
}
.text-bold {
    font-weight: 600;
}

.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-justify {
    text-align: justify;
}

.uppercase {
    text-transform: uppercase;
    letter-spacing: 2px;
}
.lowercase {
    text-transform: lowercase;
}
