.topBar {
    height: 70px;
    width: 100%;
    margin: 0;
    z-index: 10;
    border-bottom: 1px var(--prism-color-background-secondary) solid;
    background-color: var(--prism-color-background-secondary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 32px;
    color: var(--prism-color-grey-100);
}

.logo {
    height: 48px;
}

.links {
    display: flex;
    font-size: 1.15rem;
}

.link {
    margin-right: 32px;
    color: white;
    text-decoration: none;
    font-size: 15px;
}

@media (max-width: 48em) {
    .links {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: var(--prism-color-grey-100);
        z-index: 10;
        margin-top: 70px;
        opacity: 0;
        pointer-events: none;
        max-height: 0px;
        transition: 0.2s all;
        flex-direction: column;
        padding: 32px;
        font-size: 1.5rem;
    }

    .links.mobileLinksOpened {
        opacity: 1;
        max-height: 100vh;
        pointer-events: initial;
    }

    .link {
        margin-bottom: 16px;
        display: inline-block;
        color: var(--prism-color-grey-900);
    }
}

@media (min-width: 48em) {
    .mobileMenuButton {
        display: none;
    }
}
