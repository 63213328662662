html {
    font-size: 14px;
}

html:focus-within {
    scroll-behavior: smooth;
}

@media (prefers-reduced-motion) {
    html:focus-within {
        scroll-behavior: auto;
    }
}

body {
    background-color: #f8f8fa;
    color: #212934;
    font-family: "Inter", Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

:root {
    --prism-font-title: Playfair Display, Roboto Condensed, Arial condensed, sans-serif;
    --prism-font-text: Inter, Roboto, Helvetica, Arial, sans-serif;

    --blue050: hsla(214, 100%, 97%, 1);
    --blue100: hsla(214, 95%, 93%, 1);
    --blue200: hsla(213, 97%, 87%, 1);
    --blue300: hsla(212, 96%, 78%, 1);
    --blue400: hsla(213, 94%, 68%, 1);
    --blue500: hsla(217, 91%, 60%, 1);
    --blue600: hsla(221, 83%, 53%, 1);
    --blue700: hsla(224, 76%, 48%, 1);
    --blue800: hsla(226, 71%, 40%, 1);
    --blue900: hsla(224, 64%, 33%, 1);

    --pink050: hsla(356, 100%, 97%, 1);
    --pink100: hsla(356, 100%, 95%, 1);
    --pink200: hsla(353, 96%, 90%, 1);
    --pink300: hsla(353, 96%, 82%, 1);
    --pink400: hsla(351, 95%, 71%, 1);
    --pink500: hsla(350, 89%, 60%, 1);
    --pink600: hsla(347, 77%, 50%, 1);
    --pink700: hsla(345, 83%, 41%, 1);
    --pink800: hsla(343, 80%, 35%, 1);
    --pink900: hsla(342, 75%, 30%, 1);

    --green050: hsla(152, 81%, 96%, 1);
    --green100: hsla(149, 80%, 90%, 1);
    --green200: hsla(152, 76%, 80%, 1);
    --green300: hsla(156, 72%, 67%, 1);
    --green400: hsla(158, 64%, 52%, 1);
    --green500: hsla(160, 84%, 39%, 1);
    --green600: hsla(161, 94%, 30%, 1);
    --green700: hsla(163, 94%, 24%, 1);
    --green800: hsla(163, 88%, 20%, 1);
    --green900: hsla(164, 86%, 16%, 1);

    --grey100: #f7f7f7;
    --grey200: #e3e6e8;
    --grey300: #cdcfd1;
    --grey400: #b7bbc0;
    --grey500: #989ca2;
    --grey600: #6d7179;
    --grey700: #5a5c65;
    --grey800: #42454d;
    --grey900: #2c2c2d;

    --prism-color-primary: var(--pink500);
    --prism-color-secondary: var(--blue500);

    --prism-color-tertiary-green: var(--green100);
    --prism-color-tertiary-blue: var(--blue200);
    --prism-color-tertiary-pink: var(--pink200);

    --prism-color-warning: #ffaa04;
    --prism-color-success: #10ae8c;
    --prism-color-error-light: #f74b4b;
    --prism-color-error: #bf0a1c;
    --prism-color-error-dark: #99001f;

    --prism-color-white: #ffffff;

    --prism-color-background-primary: #fcfcfc;
    --prism-color-background-secondary: #262624;

    --prism-color-text: #2c2c2d;

    /* REMOVE ME AT ONE POINT PLS */
    --prism-color-grey-100: #f7f7f7;
    --prism-color-grey-200: #e3e6e8;
    --prism-color-grey-300: #cdcfd1;
    --prism-color-grey-400: #b7bbc0;
    --prism-color-grey-500: #989ca2;
    --prism-color-grey-600: #6d7179;
    --prism-color-grey-700: #5a5c65;
    --prism-color-grey-800: #42454d;
    --prism-color-grey-900: #2c2c2d;

    --text-xs: 0.75rem;
    --text-sm: 0.875rem;
    --text-base: 1rem;
    --text-lg: 1.125rem;
    --text-xl: 1.25rem;
    --text-2xl: 1.5rem;
    --text-3xl: 1.875rem;
    --text-4xl: 2.25rem;
    --text-5xl: 3rem;
    --text-6xl: 4rem;

    --shadow-medium: 0px 4px 8px rgba(50, 50, 71, 0.06), 0px 4px 4px rgba(50, 50, 71, 0.08);
}
