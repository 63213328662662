.standardNavBar {
    height: 70px;
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    align-content: center;
    width: 100%;
    background-color: var(--prism-color-background-secondary);
    z-index: 3;
}

.left {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    align-self: center;
    padding-left: 50px;
}

.volumLogo {
    height: 48px;
}

.middle {
    text-align: center;
    display: flex;
    display: flex;
    margin: auto;
    grid-column-gap: 64px;
}

.link {
    color: var(--prism-color-grey-100);
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    position: relative;
    letter-spacing: 1.4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.link:hover,
.link.active {
    color: var(--prism-color-tertiary-blue);
}

.link:hover > .tooltip {
    opacity: 1;
}

.triangle {
    color: var(--prism-color-tertiary-green);
    font-size: 1rem;
    margin-left: 4px;
}

.link:hover > .dropdown {
    opacity: 1;
    pointer-events: initial;
    top: calc(100% + 40px);
}

.dropdown {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    background: var(--prism-color-grey-100);
    z-index: 8;
    top: calc(100% + 20px);
    border: 1px var(--prism-color-grey-200) solid;
    border-top: 0;
    left: -60px;
    transition: 0.4s opacity ease-in-out, 0.2s top ease-out;
    border-radius: 3px;
    box-shadow: 0px 4px 15px rgb(0 0 0 / 20%);
}

.dropdownMouseCatcher {
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
}

.dropdownLink {
    color: var(--prism-color-grey-900);
    padding: 16px 24px;
    display: grid;
    font-size: 15px;
    letter-spacing: initial;
    column-gap: 12px;
    grid-template-columns: 24px 1fr;
    white-space: nowrap;
    align-items: flex-start;
    font-family: inherit;
    font-family: var(--prism-font-text);
    text-align: left;
    -webkit-background-clip: initial;
    background-clip: initial;
    -webkit-text-fill-color: initial;
    position: relative;
}

.dropdownLink:not(:last-child) {
    border-bottom: 1px var(--prism-color-grey-200) solid;
    padding-bottom: 16px;
}

.dropdownLink:hover {
    background-color: var(--prism-color-grey-200);
}

.dropdownIcon {
    margin-top: -1px;
    background: var(--prism-color-tertiary-blue);
    border-radius: 5px;
    height: 17px;
    width: 15px;
    color: #0d2d5e;
}

.dropdownIcon {
    stroke-width: 1.5;
    height: 20px;
    width: 20px;
}

.right {
    padding-right: 50px;
    display: grid;
    justify-content: end;
    align-content: center;
}

.userIcon {
    height: 25px;
    width: 25px;
    cursor: pointer;
    stroke-width: 1.5px;
    color: var(--prism-color-grey-100);
}

.menuIcon {
    height: 35px;
    width: 35px;
    cursor: pointer;
    stroke-width: 1.5px;
    color: var(--prism-color-grey-100);
}

.mobileNavBar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: white;
    z-index: 99;
}

.mobileNavBarClose {
    padding: 0px 18px;
    background: var(--prism-color-grey-200);
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.mobileNavBarCloseIcon {
    height: 50px;
    width: 50px;
    stroke-width: 1px;
}

.mobileLink {
    display: flex;
    column-gap: 30px;
    font-family: var(--prism-font-title);
    font-size: 19px;
    letter-spacing: 1.4px;
    color: var(--prism-color-grey-900);
    padding: 24px 32px;
    border-bottom: 1px var(--prism-color-grey-200) solid;
    align-items: center;
}

@media (max-width: 768px) {
    .left {
        padding-left: 25px;
    }

    .link {
        display: none;
    }

    .middle {
        width: 0px;
    }

    .right {
        padding-right: 25px;
    }

    .profileLink {
        display: none;
    }
}

@media (min-width: 768px) {
    .menuIcon {
        display: none;
    }
}
